import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
    <section className="hero">
      <div className="hero-overlay"></div>
      <div className="container">
        <h2 className="hero-title">MateriViz</h2>
        <p className="hero-subtitle">Transforming ideas into extraordinary visuals!</p>
        <a href="#contact" className="hero-button">Get in Touch</a>
      </div>
    </section>
  );
}

export default HeroSection;
