import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './Header.css';
import logo from '../assets/materi.png';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="header">
      <div className="container">
        <a href='/'>
          <img src={logo} alt="MateriViz Logo" className="logo" />
        </a>
        <nav className={isOpen ? 'open' : ''}>
          <ul className={`social-icons ${isOpen ? 'open' : ''}`}>
            <li><a href="https://www.facebook.com/profile.php?id=61561168053740&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></li>
            <li><a href="https://www.instagram.com/materiviz/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></li>
            <li><a href="https://www.linkedin.com/in/materi-0bab46314?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B1x%2FAZLyKR7ev3fQMcN92LA%3D%3D" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a></li>
          </ul>
        </nav>
        <div className='menu-icon' onClick={toggleMenu}>
          &#9776;
        </div>
      </div>
    </header>
  );
}

export default Header;

