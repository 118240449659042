import React, { useState } from 'react';
import './Services.css';

const services = [
  {
    name: 'Architectural Design Services',
    items: [
      'Custom Home Design',
      'Commercial Building Design',
      'Renovation and Remodeling Design',
      'Sustainable Architecture Design',
      'Green Building Design'
    ]
  },
  {
    name: 'Architectural Visualization Services',
    items: [
      'Photorealistic Rendering',
      '3D Exterior Visualization',
      'Interior Rendering',
      'Virtual Tours',
      'Architectural Animation'
    ]
  },
  {
    name: 'Building Information Modeling (BIM) Services',
    items: [
      'BIM Modeling',
      'BIM Coordination',
      'Clash Detection',
      'BIM Implementation'
    ]
  },
  {
    name: 'Conceptual Design and Feasibility Studies',
    items: [
      'Concept Development',
      'Feasibility Studies',
      'Master Planning'
    ]
  },
  {
    name: 'Urban Design and Planning',
    items: [
      'Urban Planning Visualization',
      'Site Planning',
      'Landscape Design'
    ]
  },
  {
    name: 'Interior Design Services',
    items: [
      'Interior Visualization',
      'Space Planning',
      'Furniture Layout and Design'
    ]
  },
  {
    name: 'Construction Documentation',
    items: [
      'Construction Drawings',
      'Permit Drawings',
      'Detailing and Specifications'
    ]
  },
  {
    name: 'Visualization for Marketing and Presentations',
    items: [
      'Real Estate Marketing Visuals',
      'Presentation Graphics',
      'Brochure and Portfolio Design'
    ]
  },
  {
    name: 'Virtual Reality (VR) and Augmented Reality (AR)',
    items: [
      'VR Architectural Walkthroughs',
      'AR Visualization',
      'Interactive VR Experiences'
    ]
  },
  {
    name: 'Consulting and Advisory Services',
    items: [
      'Design Consultation',
      'Sustainability Consulting',
      'Building Performance Analysis'
    ]
  },
  {
    name: 'Digital Rendering and Post-Production',
    items: [
      'Image Enhancement',
      'Video Editing',
      '360-Degree Panoramas'
    ]
  },
  {
    name: 'Custom Visualization Solutions',
    items: [
      'Tailored Visualization Services',
      'Client-Specific Rendering'
    ]
  },
  {
    name: 'Historic Preservation and Restoration',
    items: [
      'Preservation Planning',
      'Restoration Design'
    ]
  },
  {
    name: 'LEED Certification Support',
    items: [
      'Sustainable Design Consulting',
      'Energy Modeling'
    ]
  },
  {
    name: 'Educational Workshops and Training',
    items: [
      'Architecture Software Training',
      'Workshops on Visualization Techniques'
    ]
  }
];

function Services() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <section id='services' className='services'>
      <div className='container'>
        <h2>Our Services</h2>
        <div className='services-grid'>
          {services.map((service, index) => (
            <div key={index} className='services-item'>
              <div
                className='services-item-header'
                onClick={() => toggleExpand(index)}
              >
                <h3>{service.name}</h3>
                <span>{expandedIndex === index ? '-' : '+'}</span>
              </div>
              <div
                className={`collapsible-content ${
                  expandedIndex === index ? 'expanded' : ''
                }`}
              >
                <ul>
                  {service.items.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
