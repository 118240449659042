import React, { useState } from 'react';
import './Contact.css';

function Contact() {
  const [contactMethod, setContactMethod] = useState('email');
  const [contactInfo, setContactInfo] = useState('');
  const [message, setMessage] = useState('');

  const handleRadioChange = (e) => {
    setContactMethod(e.target.value);
    setContactInfo('');
    setMessage('');
  };

  const handleContactInfoChange = (e) => {
    setContactInfo(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setMessage(suggestion);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (contactMethod === 'email') {
      window.location.href = `mailto:materiviz@outlook.com?subject=Contact&body=${encodeURIComponent(message)}`;
    } else if (contactMethod === 'whatsapp') {
      window.open(`https://wa.me/+254740155726`, '_blank');
    }
  };

  return (
    <section id="contact" className="contact">
      <div className="container">
        <h2>Contact Us</h2>
        <form onSubmit={handleSubmit}>
          <div className="radio-buttons">
            <label>
              <input
                type="radio"
                value="email"
                checked={contactMethod === 'email'}
                onChange={handleRadioChange}
              />
              Email
            </label>
            <label>
              <input
                type="radio"
                value="whatsapp"
                checked={contactMethod === 'whatsapp'}
                onChange={handleRadioChange}
              />
              WhatsApp
            </label>
          </div>
          <div className="contact-input">
            {contactMethod === 'email' ? (
              <>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={contactInfo}
                  onChange={handleContactInfoChange}
                  required
                />
                <textarea
                  placeholder="Enter your message"
                  value={message}
                  onChange={handleMessageChange}
                  required
                />
                <div className="message-suggestions">
                  <p>Suggestions:</p>
                  <button type="button" onClick={() => handleSuggestionClick('I would like to know more about your services.')}>I would like to know more about your services.</button>
                  <button type="button" onClick={() => handleSuggestionClick('Can we schedule a consultation?')}>Can we schedule a consultation?</button>
                  <button type="button" onClick={() => handleSuggestionClick('I am interested in a collaboration.')}>I am interested in a collaboration.</button>
                </div>
                <button type="submit">Send Email</button>
              </>
            ) : (
              <button type="button" onClick={handleSubmit}>Contact via WhatsApp</button>
            )}
          </div>
        </form>
      </div>
    </section>
  );
}

export default Contact;
