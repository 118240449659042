// Portfolio.js
import React from 'react';
import './Portfolio.css';

import image1 from '../assets/2 final.png';
import image2 from '../assets/2.png';
import image3 from '../assets/12.png';
import image4 from '../assets/bnm.png';
import image5 from '../assets/bv.png';
import image6 from '../assets/dfe.png';
import image7 from '../assets/ds.png';
import image8 from '../assets/kitchen2.png';
import image9 from '../assets/main ent.png';
import image10 from '../assets/tchad2.png';
import image11 from '../assets/tic.jpg';

const Portfolio = () => {
    const images = [
        { id: 1, src: image1, alt: 'Modern architectural design' },
        { id: 2, src: image2, alt: 'Architectural rendering with clean lines' },
        { id: 3, src: image3, alt: 'Interior design concept' },
        { id: 4, src: image4, alt: 'Building exterior with contemporary style' },
        { id: 5, src: image5, alt: 'Ariel view exterior design' },
        { id: 6, src: image6, alt: 'Outside porch'},
        { id: 7, src: image7, alt: 'White exterior cast'},
        { id: 8, src: image8, alt: 'Kitchen Interior design'},
        { id: 9, src: image9, alt: 'Front View'},
        { id: 10, src: image10, alt: 'Parking porch view'},
        { id: 11, src: image11, alt: 'Cast walls views'}
        // Add more images as needed
    ];

    return (
        <section id="portfolio">
            <h2>Portfolio</h2>
            <div className="portfolio-grid">
                {images.map(image => (
                    <div key={image.id} className="portfolio-item">
                        <img src={image.src} alt={image.alt} />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Portfolio;
