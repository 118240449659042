import React from 'react';
import './About.css';

function About() {
  return (
    <section id="about" className="about">
      <div className="container">
        <h2>About MateriViz</h2>
        <p>
          Welcome to MateriViz, where architectural innovation meets artistic vision. Our team of dedicated professionals is passionate about creating unique, sustainable, and functional spaces that not only meet but exceed our clients' expectations. With years of experience in architectural and interior design, we pride ourselves on delivering projects that blend aesthetics with practicality.
        </p>
        <p>
          At MateriViz, we believe in a collaborative approach to design, working closely with our clients to bring their vision to life. Whether it's a residential, commercial, or landscape project, our goal is to create environments that inspire and uplift. We are committed to using cutting-edge technology and eco-friendly practices to ensure our designs are not only beautiful but also sustainable.
        </p>
        <p>
          Join us on a journey to transform spaces and redefine what's possible in architecture. Let's build something extraordinary together.
        </p>
      </div>
    </section>
  );
}

export default About;
